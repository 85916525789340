import p5 from "p5";
import { inputSketch } from "./sketches/inputSketch";
import { cnnWeightSketch } from "./models/cnn/weight";
import { cnnAiSketch } from "./models/cnn/ai";

import { simpleWeightSketch } from "./models/simple/weight";
import { simpleAiSketch } from "./models/simple/ai";

import { initKeyboardEvents } from "./userInput/keyboardInput";
import { initFrontendUserInteraction } from "./userInteraction/interaction";
import { globalState } from "./globalState";


// let logo = document.querySelector(".logo") as HTMLImageElement;
// logo.addEventListener("click", () => { globalState.switchState() });


// mounting p5 instances
// const ai = new p5(cnnAiSketch, document.querySelector("#ai-sketch") as any);
let ai = new p5(simpleAiSketch, document.querySelector("#ai-sketch") as any);

let inputSketchInstance = new p5(
  inputSketch,
  document.querySelector("#input-sketch") as any
);

// const weightSketchInstance = new p5(
//   cnnWeightSketch,
//   document.querySelector("#output-sketch") as any
// );

let weightSketchInstance = new p5(
  simpleWeightSketch,
  document.querySelector("#output-sketch") as any
);



initKeyboardEvents();
initFrontendUserInteraction();



globalState.on("stateChanged", (newState: string) => {

  console.log(globalState.state)

  if (newState === "cnn") {
    ai.remove();
    ai = new p5(cnnAiSketch, document.querySelector("#ai-sketch") as any);
    weightSketchInstance.remove();
    weightSketchInstance = new p5(
      cnnWeightSketch,
      document.querySelector("#output-sketch") as any
    );
  } else {
    ai.remove();
    ai = new p5(simpleAiSketch, document.querySelector("#ai-sketch") as any);
    weightSketchInstance.remove();
    weightSketchInstance = new p5(
      simpleWeightSketch,
      document.querySelector("#output-sketch") as any
    );
  }
});
