type Model = "cnn" | "simple";

interface GlobalState {
    state: Model;
    switchState(): void;
    changeState(newState: Model): void;
    events: { [key: string]: Function[] };
    emit(eventName: string, param?: any): void;
    on(eventName: string, cb: Function): void;
};


export const globalState: GlobalState = {

    state: "simple",  

    switchState(): void { 
        if (this.state === "simple") {
            this.emit("stateChanged", "cnn");
            this.state = "cnn"
        } else {
            this.emit("stateChanged", "simple");
            this.state = "simple"
        }
    },

    changeState(newState: Model) : void {
        this.state = newState;
        this.emit("stateChanged", newState);
    },

    events: {},
    /**
     * Löst ein Ereignis aus. Dieser Funktion dürfen beliebig viele
     * params übergeben werden, diese werden 1:1 an die Event-Listener
     * durchgereicht.
     *
     * @param {string} eventName
     * @param {*=} params
     */
    emit(eventName, param = null) {
        if (eventName in this.events) {
            for (const f of this.events[eventName]) {
                f(param)
            }
        }
    },
    /**
     * Registriert einen Event-Listener für das Event eventName.
     *
     * @param {string} eventName
     * @param {Function} cb
     */
    on(eventName, cb) {
        // Hier ist die Klammersetzung ganz wichtig!
        if (!(eventName in this.events)) {
            this.events[eventName] = []
        }
        this.events[eventName].push(cb)
    }   

}


