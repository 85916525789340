export const GRID_WIDTH = 28;
export const GRID_HEIGHT = 28;
export const PIXEL_COUNT = GRID_WIDTH * GRID_HEIGHT;

export const WEIGHT_GRID_WIDTH = 14;
export const WEIGHT_GRID_HEIGHT = 14;
export const WEIGHT_PIXEL_COUNT = WEIGHT_GRID_WIDTH * WEIGHT_GRID_HEIGHT;

export const OUTPUT_UNITS = 36;

export const LABEL_ARRAY = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const CANVAS_WIDTH = 300;
export const CANVAS_HEIGHT = 300;
