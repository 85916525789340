import p5 from "p5";
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  GRID_HEIGHT,
  GRID_WIDTH,
} from "../globals";

export let grid: number[][] = [];

export const inputSketch = (p: p5) => {
  p.disableFriendlyErrors = true;

  let tileSizeX: number;
  let tileSizeY: number;

  let brushSize = 3;
  let brushStrength = 1;

  let lastPaintedX = -1;
  let lastPaintedY = -1;

  p.setup = () => {
    p.createCanvas(CANVAS_WIDTH, CANVAS_HEIGHT);
    p.background(0);

    grid = Array.from({ length: GRID_WIDTH }, () =>
      Array.from({ length: GRID_HEIGHT }, () => 0)
    );

    tileSizeX = Math.floor((p.width * 10) / GRID_WIDTH) / 10;
    tileSizeY = Math.floor((p.height * 10) / GRID_HEIGHT) / 10;
  };

  p.draw = () => {
    if (p.mouseIsPressed == true) {
      if (mouseInsideGrid() == true) {
        let xIndex = Math.round(p.mouseX / tileSizeX);
        let yIndex = Math.round(p.mouseY / tileSizeY);

        if (xIndex != lastPaintedX || yIndex != lastPaintedY) {
          paintOnCoordinateAndSorrounding(
            xIndex,
            yIndex,
            brushSize,
            brushStrength
          );
          lastPaintedX = xIndex;
          lastPaintedY = yIndex;
        }
      }
    } else {
      lastPaintedX = -1;
      lastPaintedY = -1;
    }
    drawGrid();
  };

  function drawGrid() {
    for (let x = 0; x < GRID_WIDTH; x++)
      for (let y = 0; y < GRID_HEIGHT; y++) {
        p.fill(grid[x][y] * 255);
        p.rect(tileSizeX * x, tileSizeX * y, tileSizeX);
      }
  }

  function paintOnCoordinateAndSorrounding(x, y, brushSize, brushStrength) {
    let strengthDecreasePerTile = (brushStrength / brushSize) * 1.4;
    recursivePaintOnCoordinateAndSorrounding(
      x,
      y,
      brushStrength,
      strengthDecreasePerTile
    );
  }

  function recursivePaintOnCoordinateAndSorrounding(
    x,
    y,
    brushStrength,
    strengthDecreasePerTile
  ) {
    if (coordinatesInsideGrid(x, y)) {
      grid[x][y] += brushStrength;
      if (grid[x][y] > 1) grid[x][y] = 1;

      brushStrength -= strengthDecreasePerTile;

      if (brushStrength > 0) {
        recursivePaintOnCoordinateAndSorrounding(
          x + 1,
          y,
          brushStrength,
          strengthDecreasePerTile
        );
        recursivePaintOnCoordinateAndSorrounding(
          x - 1,
          y,
          brushStrength,
          strengthDecreasePerTile
        );
        recursivePaintOnCoordinateAndSorrounding(
          x,
          y - 1,
          brushStrength,
          strengthDecreasePerTile
        );
        recursivePaintOnCoordinateAndSorrounding(
          x,
          y + 1,
          brushStrength,
          strengthDecreasePerTile
        );
      }
    }
  }

  function mouseInsideGrid() {
    let mouseXInGrid = Math.floor(p.mouseX / tileSizeX);
    let mouseYInGrid = Math.floor(p.mouseY / tileSizeY);

    if (
      mouseXInGrid < GRID_WIDTH &&
      mouseXInGrid >= 0 &&
      mouseYInGrid < GRID_HEIGHT &&
      mouseYInGrid >= 0
    ) {
      return true;
    }

    return false;
  }

  function coordinatesInsideGrid(x, y) {
    if (x < GRID_WIDTH && x >= 0 && y < GRID_HEIGHT && y >= 0) return true;
    else return false;
  }
};

export function resetInput() {
  for (let x = 0; x < GRID_WIDTH; x++)
    for (let y = 0; y < GRID_HEIGHT; y++) grid[x][y] = 0;
}
