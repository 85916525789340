import p5 from "p5";
import { drawGrid, mapGridToCanvas, rotateAndMirrorArray, weightArray } from "./weight";
import { GRID_HEIGHT, GRID_WIDTH, LABEL_ARRAY } from "../../globals";
import * as tf from "@tensorflow/tfjs";
import { globalState } from "../../globalState";

export function simpleDrawMiniWeightSketches() {


    const MINI_WEIGHT_SKETCH_WIDTH = 70;
    const MINI_WEIGHT_SKETCH_HEIGHT = 70;

 

    weightArray.forEach((currentWeight: any, index: number) => {
        let currentChar: string = LABEL_ARRAY[index];
        let currentCharDiv = document.querySelector(
            `.char.weight[data-char="${currentChar.toLowerCase()}"]`
        )

        let weightGrid: any[] = [];
        let weightGridtileSizeX: number;
        let weightGridtileSizeY: number;

        const weightSketch = (p: p5) => {
            p.disableFriendlyErrors = true;
            p.setup = () => {
                p.createCanvas(MINI_WEIGHT_SKETCH_WIDTH, MINI_WEIGHT_SKETCH_HEIGHT);

                weightGridtileSizeX = p.width / GRID_WIDTH;
                weightGridtileSizeY = p.height / GRID_HEIGHT;

                weightGrid = Array.from({ length: 28 }, () => Array.from({ length: 28 }));

                p.frameRate(1);
            };

            p.draw = () => {
                // outputToVisualize = predictionOutputIndex;
                // getWeightsOfOutputUnitIntoWeightGrid();

                //getWeightsOfOutputUnitIntoWeightGrid();
                
                let weightTensor = tf.tensor(currentWeight, [28, 28])
                let currentWeightAsArray = weightTensor.arraySync() as any;
                
                //let rotatedAndMirroredgrid = mirro2DArray(currentWeight);
                //console.log(rotatedAndMirroredgrid)
                drawGrid(p, currentWeightAsArray, weightGridtileSizeX);
            };
        }

        function getWeightsOfOutputUnitIntoWeightGrid() {
            tf.tidy(() => {
                currentWeight = mapGridToCanvas(currentWeight)
            });
          }

    

        let miniWeightSketch;
        if (currentCharDiv) {
            miniWeightSketch = new p5(weightSketch, currentCharDiv as HTMLElement)
        }

        globalState.on("stateChanged", (newState: string) => {
            miniWeightSketch.remove();
        })


    });

}
