import { globalState } from "../globalState";
import * as cnn from "../models/cnn/ai";
import * as simple from "../models/simple/ai";


export function initKeyboardEvents() {
  let allOtherButtons = document.querySelectorAll(
    `.char`
  ) as NodeListOf<HTMLButtonElement>;

  window.addEventListener("keydown", (e) => {
    let buttons: NodeListOf<HTMLElement> = document.querySelectorAll(
      `.char[data-char="${e.key.toLowerCase()}"]`
    );
    if (buttons.length > 0) {
      removeHighlightFromAllButtons(allOtherButtons);
      buttons.forEach((button) => {
        highlightButton(button as HTMLElement);
        unHighlightButtonAfterTimeout(button as HTMLElement, 500);
      });

      if (buttons[0].dataset.char) {
        let char: string = buttons[0].dataset.char.toUpperCase();
        if (globalState.state === "cnn") {
          cnn.manualTraining(char);
        }
        if (globalState.state === "simple") {
          simple.manualTraining(char);
        }
      }
    }
  });

  allOtherButtons.forEach((button) => {
    button.addEventListener("click", () => {
      removeHighlightFromAllButtons(allOtherButtons);
      highlightButton(button);
      unHighlightButtonAfterTimeout(button, 500);

      if (button.dataset.char) {
        let char: string = button.dataset.char.toUpperCase();
        if (globalState.state === "cnn") {
          cnn.manualTraining(char);
        }
        if (globalState.state === "simple") {
          simple.manualTraining(char);
        }
      }
    });
  });
}

function highlightButton(button: HTMLElement) {
  button.classList.add("active");
}

function unHighlightButton(button: HTMLElement) {
  button.classList.remove("active");
}

function removeHighlightFromAllButtons(buttons: NodeListOf<HTMLElement>) {
  buttons.forEach((button) => {
    unHighlightButton(button);
  });
}

function unHighlightButtonAfterTimeout(button: HTMLElement, timeout: number) {
  setTimeout(() => {
    unHighlightButton(button);
  }, timeout);
}
