import { globalState } from "../globalState";
import { resetInput } from "../sketches/inputSketch";

export function initFrontendUserInteraction() {
  interface Elements {
    showKeyboardButton: HTMLElement | null;
    closeKeyboardButton: HTMLElement | null;
    keyboard: HTMLElement | null;
    correctButton: HTMLElement | null;
    resetInputButton: HTMLElement | null;

    simpleModelSwitch: HTMLElement | null;
    cnnModelSwitch: HTMLElement | null;
    miniWeightSketchHolder: HTMLElement | null;

    showKeyBoardKeyCode: number;
    isCorrectKeyboardCode: number;
    escapeKeyboardCode: number;
  }

  const elements: Elements = {
    showKeyboardButton: document.getElementById("show-keyboard"),
    closeKeyboardButton: document.getElementById("close-keyboard"),
    keyboard: document.getElementById("keyboard"),

    correctButton: document.getElementById("input-correct-btn"),
    resetInputButton: document.getElementById("reset-input-button"),

    simpleModelSwitch: document.getElementById("simple-model-switch"),
    cnnModelSwitch: document.getElementById("cnn-model-switch"),
    miniWeightSketchHolder: document.getElementById("all-weights"),


    showKeyBoardKeyCode: 32,
    isCorrectKeyboardCode: 13,
    escapeKeyboardCode: 27,
  };

  const states = {
    keyBoardVisible: true,
  };

  // On page load hide keyboard
  if (elements.keyboard) {
    hideKeyboard(elements.keyboard);
    states.keyBoardVisible = false;
  }

  if (elements.showKeyboardButton && elements.keyboard) {
    elements.showKeyboardButton.addEventListener("click", () => {
      if (states.keyBoardVisible) {
        hideKeyboard(elements.keyboard);
      } else {
        showKeyboard(elements.keyboard);
      }

      states.keyBoardVisible = !states.keyBoardVisible;
    });
  }
  if (elements.closeKeyboardButton && elements.keyboard) {
    elements.closeKeyboardButton.addEventListener("click", () => {
      hideKeyboard(elements.keyboard);
      states.keyBoardVisible = false;
    });
  }

  if (elements.resetInputButton) {
    elements.resetInputButton.addEventListener("click", () => {
      resetInput();
    });
  }

  if (elements.correctButton) {
    elements.correctButton.addEventListener("click", () => {
      resetInput();
    });
  }

  window.addEventListener("keydown", (e) => {
    e.preventDefault();
    if (e.keyCode === elements.showKeyBoardKeyCode) {
      if (states.keyBoardVisible) {
        hideKeyboard(elements.keyboard);
      } else {
        showKeyboard(elements.keyboard);
      }

      states.keyBoardVisible = !states.keyBoardVisible;
    }
    if (
      e.keyCode === elements.isCorrectKeyboardCode ||
      e.keyCode === elements.escapeKeyboardCode
    ) {
      resetInput();
    }
  });


  elements.simpleModelSwitch?.addEventListener("click", (e) => { 
    e.preventDefault();
    globalState.changeState("simple")
  });
  elements.cnnModelSwitch?.addEventListener("click", (e) => {
    e.preventDefault();
    globalState.changeState("cnn")
  });

  globalState.on("stateChanged", (newState) => {
    if (newState === "simple") {
      elements.simpleModelSwitch?.classList.add("active");
      elements.cnnModelSwitch?.classList.remove("active");
      elements.miniWeightSketchHolder?.classList.remove("hidden");
    } else {
      elements.simpleModelSwitch?.classList.remove("active");
      elements.cnnModelSwitch?.classList.add("active");
      elements.miniWeightSketchHolder?.classList.add("hidden");
    }
  });


}

export function changePredictedChar(char: string) {
  const charHolder = document.getElementById("predicted-char");
  if (charHolder) {
    charHolder.innerHTML = char;
  }
}

export function clearPredictedChar() {
  const charHolder = document.getElementById("predicted-char");
  if (charHolder) {
    charHolder.innerHTML = "";
  }
}

function showKeyboard(keyboard: HTMLElement | null) {
  keyboard?.classList.remove("hidden");
}

function hideKeyboard(keyboard: HTMLElement | null) {
  keyboard?.classList.add("hidden");
}
